import { cloneDeep, toNumber } from "lodash";
import ApiService from "@/core/services/api.service";
import ObjPath from "object-path";

// action types
export const SET_LOADING = "setLineItemLoading";
export const CLEAR_LINE_ITEM = "clearTransactionLineItem";
export const SET_DB_LINE_ITEM = "setDbLineItem";
export const SET_DOOR_TYPE_LIST = "setDoorTypeList";
export const SET_LINE_ITEM = "setLineItem";
export const SET_EDIT_LINE_ITEM = "setEditLineItem";
export const SET_VIEW_LINE_ITEM = "setViewLineItem";
export const SET_CALCULATION = "setCalculation";
export const SET_VIEW_CALCULATION = "setViewCalculation";
export const SET_EDIT_CALCULATION = "setEditCalculation";
export const SET_LINE_ITEM_ALPHA = "setLineItemAlpha";
export const SET_LINE_ITEM_CATEGORY = "setLineItemCategory";
export const GET_LINE_ITEM = "getLineItem";
export const SET_UOM = "setLineItemUom";
export const SET_GST = "setLineItemGst";

export default {
  state: {
    db_loading: false,
    db_gst: 0,
    db_line_items: [],
    db_door_types: [],
    db_selected_line_items: [],
    db_view_line_items: [],
    db_calculations: {},
    db_view_calculations: {
      additional_rows: [],
      sub_total: 0,
      discount_type: 1,
      discount_value: 0,
      discount_amount: 0,
      tax_active: false,
      tax_value: 0,
      tax_amount: 0,
      adjustment: 0,
      total: 0,
      show_price: true,
      discount_level: "transaction",
    },
    db_edit_line_items: [],
    db_edit_calculations: {},
    db_item_categories: [],
    db_item_alphabets: [],
    db_uoms: [],
  },
  getters: {
    lProcessing(state) {
      return state.db_loading;
    },
    lUOM(state) {
      return state.db_uoms;
    },
    lGST(state) {
      return state.db_gst;
    },
    lCalculation(state) {
      return state.db_calculations;
    },
    lDBLineItem(state) {
      return state.db_line_items;
    },
    lDoorTypeList(state) {
      return state.db_door_types;
    },
    lViewLineItem(state) {
      return state.db_view_line_items;
    },
    lSelectedLineItem(state) {
      return state.db_selected_line_items;
    },
    lEditLineItem(state) {
      return state.db_edit_line_items;
    },
    lEditCalculation(state) {
      return state.db_edit_calculations;
    },
    lViewCalculation(state) {
      return state.db_view_calculations;
    },
    lItemCategory(state) {
      return state.db_item_categories;
    },
    lItemAlpha(state) {
      return state.db_item_alphabets;
    },
  },
  actions: {
    [CLEAR_LINE_ITEM](context) {
      context.commit(SET_LOADING, false);
      context.commit(SET_DB_LINE_ITEM, []);
      context.commit(SET_DOOR_TYPE_LIST, []);
      context.commit(SET_LINE_ITEM, []);
      context.commit(SET_CALCULATION, {});
      context.commit(SET_LINE_ITEM_ALPHA, []);
      context.commit(SET_LINE_ITEM_CATEGORY, []);
      context.commit(SET_UOM, []);
      context.commit(SET_GST, 0);
    },
    [GET_LINE_ITEM](context, payload) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LINE_ITEM_ALPHA, []);
        context.commit(SET_LINE_ITEM_CATEGORY, []);
        context.commit(SET_DB_LINE_ITEM, []);
        context.commit(SET_DOOR_TYPE_LIST, []);
        ApiService.setHeader();
        context.commit(SET_LOADING, true);
        ApiService.query("product/find-line-items", payload)
          .then((response) => {
            context.commit(SET_LINE_ITEM_ALPHA, ObjPath.get(response, "data.data.all_alpha", []));
            context.commit(SET_LINE_ITEM_CATEGORY, ObjPath.get(response, "data.data.categories", []));
            context.commit(SET_DB_LINE_ITEM, ObjPath.get(response, "data.data.line_items", []));
            context.commit(SET_DOOR_TYPE_LIST, ObjPath.get(response, "data.data.door_types", []));
            resolve(response?.data?.data);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [SET_LINE_ITEM](context, payload) {
      context.commit(SET_LINE_ITEM, payload);
    },
    [SET_EDIT_LINE_ITEM](context, payload) {
      context.commit(SET_EDIT_LINE_ITEM, payload);
    },
    [SET_EDIT_CALCULATION](context, payload) {
      context.commit(SET_EDIT_CALCULATION, payload);
    },
    [SET_VIEW_CALCULATION](context, payload) {
      context.commit(SET_VIEW_CALCULATION, payload);
    },
    [SET_UOM](context, payload) {
      context.commit(SET_UOM, payload);
    },
    [SET_GST](context, payload) {
      context.commit(SET_GST, payload);
    },
    [SET_LOADING](context, payload) {
      context.commit(SET_LOADING, payload);
    },
    [SET_DB_LINE_ITEM](context, payload) {
      context.commit(SET_DB_LINE_ITEM, payload);
    },
    [SET_DOOR_TYPE_LIST](context, payload) {
      context.commit(SET_DOOR_TYPE_LIST, payload);
    },
    [SET_CALCULATION](context, payload) {
      context.commit(SET_CALCULATION, payload);
    },
    [SET_VIEW_LINE_ITEM](context, payload) {
      context.commit(SET_VIEW_LINE_ITEM, payload);
    },
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.db_loading = payload;
    },
    [SET_LINE_ITEM](state, payload) {
      state.db_selected_line_items = cloneDeep(payload);
    },
    [SET_EDIT_LINE_ITEM](state, payload) {
      state.db_edit_line_items = cloneDeep(payload);
    },
    [SET_EDIT_CALCULATION](state, payload) {
      state.db_edit_calculations = cloneDeep(payload);
    },
    [SET_VIEW_CALCULATION](state, payload) {
      state.db_view_calculations = cloneDeep(payload);
    },
    [SET_UOM](state, payload) {
      state.db_uoms = cloneDeep(payload);
    },
    [SET_GST](state, payload) {
      state.db_gst = toNumber(payload);
    },
    [SET_CALCULATION](state, payload) {
      state.db_calculations = cloneDeep(payload);
    },
    [SET_VIEW_LINE_ITEM](state, payload) {
      state.db_view_line_items = cloneDeep(payload);
    },
    [SET_DB_LINE_ITEM](state, payload) {
      state.db_line_items = cloneDeep(payload);
    },
    [SET_DOOR_TYPE_LIST](state, payload) {
      state.db_door_types = cloneDeep(payload);
    },
    [SET_LINE_ITEM_ALPHA](state, payload) {
      state.db_item_alphabets = cloneDeep(payload);
    },
    [SET_LINE_ITEM_CATEGORY](state, payload) {
      state.db_item_categories = cloneDeep(payload);
    },
  },
};
