import ApiService from "@/core/services/api.service";
import moment from "moment-timezone";
import ObjectPath from "object-path";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ManageSchedule from "@/core/plugins/job.plugin";
import { find, cloneDeep, toSafeInteger } from "lodash";

import { SET_UOM, SET_GST } from "@/core/services/store/line.item.module";

import { SET_EDIT_LINE_ITEM, SET_EDIT_CALCULATION } from "@/core/services/store/line.item.module";

import { RESET_STATE } from "@/core/services/store/visit.module";
import { SET_CUSTOMER, SET_BILLING, SET_BILLING_CONTACT, SET_PROPERTY, SET_PROPERTY_CONTACT } from "@/core/services/store/customer.module";

import { toSafeNumber } from "@/core/plugins/math.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

const todayDate = moment(new Date());

const formatDate = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format("DD MMMM YYYY hh:mm A");
  }
};

const validScheduleType = ["daily", "weekly", "monthly"];

const adjustedStartTime = moment(todayDate)
  .startOf("hour")
  .add(Math.ceil(todayDate.minutes() / 60) * 60, "minutes");
const adjustedStartEnd = moment(todayDate)
  .add(1, "hour")
  .startOf("hour")
  .add(Math.ceil(todayDate.minutes() / 60) * 60, "minutes");

const initialSchedule = {
  start_date: moment(todayDate).format("YYYY-MM-DD"),
  end_date: moment(todayDate).format("YYYY-MM-DD"),
  start_time: adjustedStartTime.format("hh:mm A"),
  end_time: adjustedStartEnd.format("hh:mm A"),
};

const initialRecurringSchedule = {
  start_date: moment(todayDate).format("YYYY-MM-DD"),
  end_date: moment(todayDate).add(1, "day").format("YYYY-MM-DD"),
  start_time: adjustedStartTime.format("hh:mm A"),
  end_time: adjustedStartEnd.format("hh:mm A"),
};

// action types
export const CLEAR_TICKET = "JtxJobClearTicket";
export const GET_TICKET = "JtxJobGetTicket";
export const SET_TICKET = "JtxJobSetTicket";
export const GET_OPTION = "JtxJobGetOption";
export const SET_OPTION = "JtxJobSetOption";
export const CREATE_OR_UPDATE = "JtxJobCreateOrUpdate";
export const UPDATE_CALENDAR_ACTION = "JtxJobUpdateCalendarAction";
export const UPDATE_SCHEDULE_STATE = "JtxJobUpdateScheduleState";
export const UPDATE_SCHEDULE_ENGINEER = "JtxJobUpdateScheduleEngineer";
export const GET_AVAILABLE_ENGINEER = "JtxJobGetAvailableEngineer";
export const SET_AVAILABLE_ENGINEER = "JtxJobSetAvailableEngineer";
export const REMOVE_ENGINEER = "JtxJobRemoveEngineer";
export const RESET_ENGINEER = "JtxJobResetEngineer";
export const SET_SCHEDULE_TYPE = "JtxJobSetScheduleType";
export const GET_RECURRING_DATE = "JtxJobGetRecurringDate";
export const SET_RECURRING_DATE = "JtxJobSetRecurringDate";
export const SET_RAW_RECURRING_DATE = "JtxJobSetRawRecurringDate";
export const SET_LOADING = "JtxJobSetJobLoading";
export const UPDATE_DEFAULT_DATE = "JtxJobUpdateDefaultDate";

const dbDurationList = [
  { id: 1, short_name: "1st", long_name: "First" },
  { id: 2, short_name: "2nd", long_name: "Second" },
  { id: 3, short_name: "3rd", long_name: "Third" },
  { id: 4, short_name: "4th", long_name: "Fourth" },
  { id: 5, short_name: "Last", long_name: "Last" },
];

const dbWeekDays = [
  { id: 1, short_name: "Sun", long_name: "Sunday" },
  { id: 2, short_name: "Mon", long_name: "Monday" },
  { id: 3, short_name: "Tue", long_name: "Tuesday" },
  { id: 4, short_name: "Wed", long_name: "Wednesday" },
  { id: 5, short_name: "Thu", long_name: "Thursday" },
  { id: 6, short_name: "Fri", long_name: "Friday" },
  { id: 7, short_name: "Sat", long_name: "Saturday" },
];

const dbRecurringScheduleTypeList = [
  { max_allowed: 0, group: "daily", text: "Daily", value: "daily" },
  { max_allowed: 1, group: "weekly", text: "Weekly", value: "weekly" },
  {
    max_allowed: 2,
    group: "weekly",
    text: "Twice a Week",
    value: "twice_a_week",
  },
  {
    max_allowed: 1,
    group: "weekly",
    text: "Fort-Nightly",
    value: "fort_nightly",
  },
  {
    max_allowed: 3,
    group: "weekly",
    text: "Thrice a Week",
    value: "thrice_a_week",
  },
  { max_allowed: 0, group: "monthly", text: "Monthly", value: "monthly" },
  {
    max_allowed: 0,
    group: "monthly",
    text: "Twice a Month",
    value: "twice_a_month",
  },
  {
    max_allowed: 0,
    group: "monthly",
    text: "BI-Monthly",
    value: "bi_monthly",
  },
  {
    max_allowed: 0,
    group: "monthly",
    text: "Quarterly",
    value: "quarterly",
  },
  {
    max_allowed: 0,
    group: "monthly",
    text: "BI-Annually",
    value: "bi_annually",
  },
  { max_allowed: 0, group: "monthly", text: "Annually", value: "annually" },
];

const dbJobScheduleTypeList = [
  {
    id: "one-off",
    value: "One-Off Job",
  },
  {
    id: "recurring",
    value: "Recurring Job",
  },
];

const dbOneOffSchedule = {
  start_date: initialSchedule.start_date /* FORMAT - YYYY-MM-DD*/,
  end_date: initialSchedule.end_date /* FORMAT - YYYY-MM-DD*/,
  start_time: initialSchedule.start_time /* FORMAT - hh:mm A*/,
  end_time: initialSchedule.end_time /* FORMAT - hh:mm A*/,
};

const dbRecurringSchedule = {
  type: {
    group: "daily",
    text: "Daily",
    value: "daily",
  },
  weeks: [],
  occurrence: 1,
  week_day: 1,
  week_count: 1,
  second_week_day: 5,
  second_week_count: 1,
  recurring_pattern: 1,
  message: null,
  end_mode: 2 /* occurrences = 2; end-date = 3 */,
  start_date: initialRecurringSchedule.start_date /* FORMAT - YYYY-MM-DD*/,
  end_date: initialRecurringSchedule.end_date /* FORMAT - YYYY-MM-DD*/,
  start_time: initialRecurringSchedule.start_time /* FORMAT - hh:mm A*/,
  end_time: initialRecurringSchedule.end_time /* FORMAT - hh:mm A*/,
};

// mutation types
export default {
  state: {
    dbDefaultStartedAt: null,
    dbDefaultFinishedAt: null,
    dbShowCalendar: false,
    dbScheduleType: "one-off",
    dbAvailableTeam: [],
    dbAssignedTeam: [],
    dbAssignedTeamObject: [],
    dbDurationList: cloneDeep(dbDurationList),
    dbWeekDays: cloneDeep(dbWeekDays),
    dbRecurringScheduleTypeList: cloneDeep(dbRecurringScheduleTypeList),
    dbJobScheduleTypeList: cloneDeep(dbJobScheduleTypeList),
    dbOneOffSchedule: cloneDeep(dbOneOffSchedule),
    dbRecurringSchedule: cloneDeep(dbRecurringSchedule),
    dbScheduleOutput: [],
    dbRawScheduleOutput: [],
    dbJobOption: {},
    dbJobLoading: false,
    dbJobEditData: {},
  },
  getters: {
    defaultStartedAt(state) {
      return state.dbDefaultStartedAt;
    },
    defaultFinishedAt(state) {
      return state.dbDefaultFinishedAt;
    },
    jobEditData(state) {
      return state.dbJobEditData;
    },
    jobOption(state) {
      return state.dbJobOption;
    },
    scheduleOutput(state) {
      return state.dbScheduleOutput;
    },
    rawScheduleOutput(state) {
      return state.dbRawScheduleOutput;
    },
    scheduleType(state) {
      return state.dbScheduleType;
    },
    jobLoading(state) {
      return state.dbJobLoading;
    },
    oneOffSchedule(state) {
      return state.dbOneOffSchedule;
    },
    recurringSchedule(state) {
      return state.dbRecurringSchedule;
    },
    vShowCalendar(state) {
      return state.dbShowCalendar;
    },
    weekDays(state) {
      return state.dbWeekDays;
    },
    durationList(state) {
      return state.dbDurationList;
    },
    recurringScheduleTypeList(state) {
      return state.dbRecurringScheduleTypeList;
    },
    assignedTeam(state) {
      return state.dbAssignedTeam;
    },
    availableTeam(state) {
      return state.dbAvailableTeam;
    },
    assignedTeamObject(state) {
      return state.dbAssignedTeamObject;
    },
    jobScheduleTypeList(state) {
      return state.dbJobScheduleTypeList;
    },
  },
  actions: {
    [CREATE_OR_UPDATE](context, payload) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING, true);
        let requestType = "post";
        let requestURL = "ticket";
        let ticketId = payload?.ticket ?? 0;
        let visitId = payload?.visit ?? 0;
        if (ticketId && visitId) {
          requestType = "put";
          requestURL = `ticket/${ticketId}/${visitId}`;
        }
        ApiService.setHeader();
        ApiService[requestType](requestURL, payload)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(false);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [GET_TICKET](context, { ticket, visit }) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING, true);
        context.commit(SET_TICKET, {});
        ApiService.setHeader();
        ApiService.get(`ticket/${ticket}/${visit}`)
          .then(({ data }) => {
            if (data?.data?.ticket?.type == 1) {
              const startedAt = data?.data?.visit?.started_at;
              const finishedAt = data?.data?.visit?.finished_at;

              context.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbOneOffSchedule.start_date",
                value: moment(startedAt).format("YYYY-MM-DD"),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbOneOffSchedule.end_date",
                value: moment(finishedAt).format("YYYY-MM-DD"),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbOneOffSchedule.start_time",
                value: moment(startedAt).format("hh:mm A"),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbOneOffSchedule.end_time",
                value: moment(finishedAt).format("hh:mm A"),
              });
            }

            if (data?.data?.ticket?.type == 2 && data?.data?.ticket?.btx_schedule_type == "recurring") {
              const schedule = data?.data?.daily ?? data?.data?.weekly ?? data?.data?.monthly;

              const startedAt = new Date(`${schedule?.start_date} ${schedule?.start_time}`);
              const finishedAt = new Date(`${schedule?.end_date} ${schedule?.end_time}`);

              context.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt });

              const type = context.state.dbRecurringScheduleTypeList.find(
                (row) => row.value == data?.data?.ticket?.btx_schedule_tab
              );

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.type",
                value: type,
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.weeks",
                value: schedule?.weeks ?? [],
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.occurrence",
                value: toSafeNumber(schedule.occurrence, 1),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.end_mode",
                value: toSafeNumber(schedule.end_mode, 1),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.start_date",
                value: moment(startedAt).format("YYYY-MM-DD"),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.end_date",
                value: moment(finishedAt).format("YYYY-MM-DD"),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.start_time",
                value: moment(startedAt).format("hh:mm A"),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.end_time",
                value: moment(finishedAt).format("hh:mm A"),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.week_day",
                value: toSafeNumber(schedule.week_day, 1),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.week_count",
                value: toSafeNumber(schedule.week_count, 1),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.second_week_day",
                value: toSafeNumber(schedule.second_week_day, 1),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.second_week_count",
                value: toSafeNumber(schedule.second_week_count, 1),
              });

              context.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.recurring_pattern",
                value: toSafeNumber(schedule.recurring_pattern, 1),
              });

              context.dispatch(GET_RECURRING_DATE);

            }

            context.commit(SET_CUSTOMER, data?.data?.customer ?? {});
            context.commit(SET_PROPERTY, data?.data?.property ?? {});
            context.commit(SET_PROPERTY_CONTACT, data?.data?.propertycontactperson ?? {});
            context.commit(SET_BILLING, data?.data?.billing ?? {});
            context.commit(SET_BILLING_CONTACT, data?.data?.billingcontactperson ?? {});

            context.commit(SET_AVAILABLE_ENGINEER, data?.data?.available_team ?? []);

            context.commit(UPDATE_SCHEDULE_STATE, { key: "dbAssignedTeamObject", value: data?.data?.team });
            context.commit(UPDATE_SCHEDULE_STATE, { key: "dbAssignedTeam", value: data?.data?.team?.map((row) => row.engineer_id) });

            context.commit(UPDATE_SCHEDULE_STATE, {
              key: "dbJobOption.visit_barcode",
              value: data?.data?.visit?.barcode,
            });

            context.dispatch(SET_EDIT_CALCULATION, {
              additional_rows: data?.data?.ticket?.additional_rows ?? [],
              ctx_discount_label: data?.data?.ticket?.ctx_discount_label ?? null,
              ctx_discount_value: data?.data?.ticket?.ctx_discount_value ?? 0,
              ctx_discount_type: data?.data?.ticket?.ctx_discount_type ?? 1,
              discount_type: data?.data?.ticket?.discount_type ?? 1,
              discount_value: data?.data?.ticket?.discount_value ?? 0,
              tax_active: data?.data?.ticket?.tax_active ?? false,
              adjustment: data?.data?.ticket?.adjustment ?? 0,
              show_price: true,
              discount_level: data?.data?.ticket?.discount_level ?? "transaction",
            });

            context.dispatch(SET_EDIT_LINE_ITEM, data?.data?.line_item ?? []);

            /*
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.subtotal",
              value: parseFloat(data?.data?.ticket?.sub_total ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.discount_amount",
              value: parseFloat(data?.data?.ticket?.discount_amount ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.discount_type",
              value: parseFloat(data?.data?.ticket?.discount_value_type ?? 1),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.discount_value",
              value: parseFloat(data?.data?.ticket?.discount_value ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.tax_amount",
              value: parseFloat(data?.data?.ticket?.tax_amount ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.tax_value",
              value: parseFloat(data?.data?.ticket?.tax_value ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.tax_active",
              value: parseFloat(data?.data?.ticket?.tax_applied ?? 1),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.adjustment",
              value: parseFloat(data?.data?.ticket?.adjustment ?? 0),
            });
            context.commit(UPDATE_LINE_ITEM_STATE, {
              key: "dbCalculation.total",
              value: parseFloat(data?.data?.ticket?.total ?? 0),
            });

            context.commit(SET_SELECTED_LINE_ITEM, data?.data?.line_item ?? {}); */

            context.commit(SET_TICKET, data?.data, {});

            resolve(true);
          })
          .catch(() => {
            context.commit(SET_TICKET, {});
            reject(false);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    /* [GET_OPTION](context) {
      context.commit(SET_LOADING, true);
      context.commit(SET_OPTION, {});
      ApiService.setHeader();
      ApiService.query("ticket/options", { ...router?.currentRoute?.query })
        .then(({ data }) => {
          context.commit(SET_OPTION, data?.data ?? {});
        })
        .catch(() => {
          context.commit(SET_OPTION, {});
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    }, */
    [GET_OPTION](context, request) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        context.commit(SET_LOADING, true);
        ApiService.query("job/options", {
          ...request,
          customer: toSafeInteger(request?.customer),
          billing: toSafeInteger(context?.state?.db_billing?.id),
          property: toSafeInteger(context?.state?.db_property?.id),
          property_person: toSafeInteger(context?.state?.db_property_contact?.id),
          billing_person: toSafeInteger(context?.state?.db_billing_contact?.id),
        })
          .then((response) => {
            console.log(response, "response")

            context.dispatch(SET_UOM, ObjectPath.get(response, "data.data.uom_list", []));
            context.dispatch(SET_GST, ObjectPath.get(response, "data.data.entity.gstvalue", 0));

            const adminNoteList = ObjectPath.get(response, "data.data.admin_notes_list", []);

            const clientNoteList = ObjectPath.get(response, "data.data.client_notes_list", []);

            const termsConditionList = ObjectPath.get(response, "data.data.term_condition_list", []);

            context.commit(SET_OPTION, ObjectPath.get(response, "data.data", {}));

            resolve({
              adminNoteList,
              clientNoteList,
              termsConditionList
            });
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      });
    },
    [GET_AVAILABLE_ENGINEER](context, payload) {
      context.commit(SET_LOADING, true);
      context.commit(RESET_ENGINEER);
      ApiService.setHeader();
      ApiService.query("ticket/available-engineers", payload)
        .then(({ data }) => {
          context.commit(SET_AVAILABLE_ENGINEER, data?.data ?? []);
        })
        .catch(() => {
          context.commit(SET_AVAILABLE_ENGINEER, []);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    },

    [GET_RECURRING_DATE](context) {
      const recurringSchedule = context.state.dbRecurringSchedule;

      if (!validScheduleType.includes(recurringSchedule?.type?.group)) {
        return false;
      }

      const resetRecurringSchedule = () => {
        context.commit(UPDATE_SCHEDULE_STATE, {
          key: "dbRecurringSchedule.message",
          value: null,
        });
        context.commit(UPDATE_SCHEDULE_STATE, {
          key: "dbRecurringSchedule.end_date",
          value: recurringSchedule.end_date,
        });
        context.commit(SET_RECURRING_DATE, []);
        context.commit(SET_RAW_RECURRING_DATE, []);
      };

      const startDate = moment(
        `${recurringSchedule.start_date} ${recurringSchedule.start_time}`
      ).format("YYYY-MM-DD HH:mm:ss");
      const occurrences =
        recurringSchedule.end_mode == 2
          ? toSafeNumber(recurringSchedule.occurrence)
          : 1000;
      const endDate =
        recurringSchedule.end_mode == 3
          ? moment(
              `${recurringSchedule.end_date} ${recurringSchedule.end_time}`
            ).format("YYYY-MM-DD HH:mm:ss")
          : null;
      const selectedDays = recurringSchedule.weeks;

      if (recurringSchedule?.type?.group == "weekly" && !selectedDays.length) {
        resetRecurringSchedule();
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select weekday.")
        );
        return false;
      }

      const weekDay = toSafeNumber(recurringSchedule.week_day, -1);
      const weekCount = toSafeNumber(recurringSchedule.week_count, -1);
      const secondWeekDay = toSafeNumber(recurringSchedule.second_week_day, -1);
      const secondWeekCount = toSafeNumber(
        recurringSchedule.second_week_count,
        -1
      );
      const pattern = toSafeNumber(recurringSchedule.recurring_pattern, 1);
      const endMode = toSafeNumber(recurringSchedule.end_mode, 2);

      const scheduleManager = new ManageSchedule(
        startDate,
        occurrences,
        endDate,
        endMode,
        pattern,
        weekDay,
        weekCount,
        secondWeekDay,
        secondWeekCount
      );

      let scheduleDate = [];

      if (recurringSchedule?.type?.value == "daily") {
        scheduleDate = scheduleManager.generateDailySchedule();
      }

      if (recurringSchedule?.type?.group == "weekly") {
        if (recurringSchedule?.type?.value == "weekly") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            1,
            selectedDays.slice(0, 1)
          );
        }

        if (recurringSchedule?.type?.value == "twice_a_week") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            1,
            selectedDays.slice(0, 2)
          );
        }

        if (recurringSchedule?.type?.value == "fort_nightly") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            2,
            selectedDays.slice(0, 1)
          );
        }

        if (recurringSchedule?.type?.value == "thrice_a_week") {
          scheduleDate = scheduleManager.generateWeeklySchedule(
            1,
            selectedDays.slice(0, 3)
          );
        }
      }

      if (recurringSchedule?.type?.group == "monthly") {
        if (recurringSchedule?.type?.value == "monthly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(1);
        }

        if (recurringSchedule?.type?.value == "twice_a_month") {
          scheduleDate = scheduleManager.generateTwiceAMonthlySchedule();
        }

        if (recurringSchedule?.type?.value == "bi_monthly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(2);
        }

        if (recurringSchedule?.type?.value == "quarterly") {
          scheduleDate = scheduleManager.generateMonthlySchedule(3);
        }

        if (recurringSchedule?.type?.value == "bi_annually") {
          scheduleDate = scheduleManager.generateMonthlySchedule(6);
        }

        if (recurringSchedule?.type?.value == "annually") {
          scheduleDate = scheduleManager.generateMonthlySchedule(12);
        }
      }

      if (!scheduleDate.length) {
        resetRecurringSchedule();
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Oops... Dates are not available, please select valid schedule."
          )
        );
        return false;
      }

      const scheduleStartDate = scheduleDate.length ? scheduleDate[0] : null;
      const scheduleEndDate = scheduleDate.length
        ? new Date(scheduleDate[scheduleDate.length - 1])
        : null;

      const endTime = moment(
        `${recurringSchedule.start_date} ${recurringSchedule.end_time}`
      ).format("HH:mm:ss");

      let [hour, minute, second] = endTime.split(":");

      if (scheduleEndDate instanceof Date) {
        scheduleEndDate.setHours(hour);
        scheduleEndDate.setMinutes(minute);
        scheduleEndDate.setSeconds(second);
      }

      const message = String(scheduleManager.message)
        .replace("{TYPE}", recurringSchedule?.type?.text)
        .replace("{START_DATE_TIME}", formatDate(scheduleStartDate))
        .replace("{OCCURRENCE}", scheduleDate.length)
        .replace("{END_DATE_TIME}", formatDate(scheduleEndDate));

      context.commit(UPDATE_SCHEDULE_STATE, {
        key: "dbRecurringSchedule.message",
        value: message,
      });
      /*  context.commit(UPDATE_SCHEDULE_STATE, {
        key: "dbRecurringSchedule.end_date",
        value: moment(scheduleEndDate).format("YYYY-MM-DD"),
      }); */

      const combineDate = scheduleManager.combineDate(scheduleDate, endTime);

      context.commit(SET_RECURRING_DATE, combineDate);
      context.commit(SET_RAW_RECURRING_DATE, combineDate);
    },
    [CLEAR_TICKET](context) {
      context.commit(CLEAR_TICKET);
      context.commit(RESET_STATE);
      /*   context.commit(CLEAR_LINE_ITEM); */
    },
    [UPDATE_DEFAULT_DATE](context, payload) {
      let startedAt = null;
      let finishedAt = null;

      if (payload == "one-off") {
        startedAt = `${context.state.dbOneOffSchedule.start_date} ${context.state.dbOneOffSchedule.start_time}`;
        finishedAt = `${context.state.dbOneOffSchedule.end_date} ${context.state.dbOneOffSchedule.end_time}`;
      }

      if (context.state.dbJobEditData?.ticket?.id) {
        return false;
      }

      if (payload == "recurring") {
        startedAt = `${context.state.dbRecurringSchedule.start_date} ${context.state.dbRecurringSchedule.start_time}`;
        finishedAt = `${context.state.dbRecurringSchedule.end_date} ${context.state.dbRecurringSchedule.end_time}`;
      }

      context.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt });
    },
  },
  mutations: {
    [CLEAR_TICKET](state) {
      state.dbDefaultStartedAt = null;
      state.dbDefaultFinishedAt = null;
      state.dbShowCalendar = false;
      state.dbScheduleType = "one-off";
      state.dbAvailableTeam = [];
      state.dbAssignedTeam = [];
      state.dbAssignedTeamObject = [];
      state.dbDurationList = cloneDeep(dbDurationList);
      state.dbWeekDays = cloneDeep(dbWeekDays);
      state.dbRecurringScheduleTypeList = cloneDeep(
        dbRecurringScheduleTypeList
      );
      state.dbJobScheduleTypeList = cloneDeep(dbJobScheduleTypeList);
      state.dbOneOffSchedule = cloneDeep(dbOneOffSchedule);
      state.dbRecurringSchedule = cloneDeep(dbRecurringSchedule);
      state.dbScheduleOutput = [];
      state.dbRawScheduleOutput = [];
      state.dbJobOption = {};
      state.dbJobLoading = false;
      state.dbJobEditData = {};
    },
    [SET_TICKET](state, payload) {
      state.dbJobEditData = cloneDeep(payload);
    },
    [UPDATE_DEFAULT_DATE](state, { startedAt, finishedAt }) {
      state.dbDefaultStartedAt = startedAt
        ? moment(startedAt).format("YYYY-MM-DD HH:mm")
        : null;
      state.dbDefaultFinishedAt = finishedAt
        ? moment(finishedAt).format("YYYY-MM-DD HH:mm")
        : null;
    },
    [SET_OPTION](state, payload) {
      state.dbJobOption = cloneDeep(payload);
    },
    [SET_RECURRING_DATE](state, payload) {
      state.dbScheduleOutput = cloneDeep(payload);
    },
    [SET_RAW_RECURRING_DATE](state, payload) {
      state.dbRawScheduleOutput = cloneDeep(payload);
    },
    [RESET_ENGINEER](state) {
      state.dbAssignedTeam = [];
      state.dbAvailableTeam = [];
      state.dbAssignedTeamObject = [];
    },
    [REMOVE_ENGINEER](state, payload) {
      state.dbAssignedTeam = cloneDeep(
        state.dbAssignedTeam.filter((obj) => payload != obj)
      );
      state.dbAssignedTeamObject = cloneDeep(
        state.dbAssignedTeamObject.filter((obj) => payload != obj.engineer_id)
      );
    },
    [UPDATE_SCHEDULE_ENGINEER](state) {
      let dbAvailableTeam = cloneDeep(state.dbAvailableTeam);
      let dbAssignedTeam = cloneDeep(state.dbAssignedTeam);
      let objectAssignedTeam = [];
      for (let i = 0; i < dbAssignedTeam.length; i++) {
        let exists = find(dbAvailableTeam, { engineer_id: dbAssignedTeam[i] });
        if (exists) {
          objectAssignedTeam.push(exists);
        }
      }
      state.dbAssignedTeamObject = cloneDeep(objectAssignedTeam);
    },
    [SET_LOADING](state, payload) {
      state.dbJobLoading = payload;
    },
    [SET_SCHEDULE_TYPE](state, payload) {
      state.dbScheduleType = payload;
    },
    [SET_AVAILABLE_ENGINEER](state, payload) {
      state.dbAvailableTeam = cloneDeep(payload);
      if (!payload.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Oops.. No Engineers are available.")
        );
      }
    },
    [UPDATE_CALENDAR_ACTION](state) {
      state.dbShowCalendar = !state.dbShowCalendar;
    },
    [UPDATE_SCHEDULE_STATE](state, { key, value }) {
      ObjectPath.set(state, key, cloneDeep(value));
    },
  },
};
